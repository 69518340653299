import { Category, Industory } from "@/types/types";
import {
  articleCategories,
  industories,
  newsCategories,
  serviceCategories,
} from "@/constants/constants";

const fallbackCopyTextToClipboard = (text: string) => {
  const textarea = document.createElement("textarea");
  textarea.style.position = "absolute";
  textarea.style.opacity = "0";
  textarea.style.pointerEvents = "none";
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.setSelectionRange(0, 999999);
  document.execCommand("copy");
  textarea.parentNode?.removeChild(textarea);
};

export const copyText = async (text: string) => {
  if (navigator.clipboard) {
    // モダンブラウザ
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      fallbackCopyTextToClipboard(text);
    }
  } else {
    // 古いブラウザ
    fallbackCopyTextToClipboard(text);
  }
};

export const copyAndShare = async (
  event: React.MouseEvent<HTMLAnchorElement>,
  url: string
) => {
  event.preventDefault();
  const target = event.currentTarget as HTMLAnchorElement;
  const href = target.getAttribute("href");
  if (!href) return;
  await copyText(url);
  window.open(href, "_blank");
};

export const openLink = (
  event: React.MouseEvent<HTMLAnchorElement>,
  url: string
) => {
  event.preventDefault();
  window.open(url, "_blank");
};

export const getSecondCategoryJa = (
  categoryEn: string,
  categories: Category[]
) => {
  let result = "";
  categories.forEach((item) => {
    const findResult = item.secondCategories.find(
      (item) => item.category === categoryEn
    );
    if (findResult) result = findResult.name;
  });
  return result;
};

export const getIndustoryJa = (categoryEn: string) => {
  return industories.find((item) => item.category === categoryEn)?.name || "";
};

export const getCategoryInfo = (
  categories: string[],
  dir?: "article" | "news" | "service"
) => {
  // 結果の型を修正 - すべてのプロパティを文字列かundefinedで受け入れるよう変更
  const result: {
    firstCategoryJa: string;
    firstCategoryEn: string;
    secondCategoryJa: string;
    secondCategoryEn: string;
    description: string;
    adImage1: string;
    adImage2: string;
    bgImage: string;
    richDescription: string;
  }[] = [];
  if (!categories || !Array.isArray(categories)) return result;

  const _categories = (() => {
    switch (dir) {
      case "article":
        return articleCategories;
      case "news":
        return newsCategories as any; // 型アサーションで一時的に回避
      case "service":
        return serviceCategories;
    }
  })();

  categories.forEach((item) => {
    // カテゴリID（itemの値）をデバッグ出力
    console.log(`処理中のカテゴリID: ${item}`);
    
    _categories.forEach((firstCategory: Category) => {
      // まずサブカテゴリを検索（サブカテゴリが優先）
      const findResult = firstCategory.secondCategories.find(
        (secondCategory: { category: string; }) => secondCategory.category === item
      );
      
      if (findResult) {
        console.log(`サブカテゴリ ${item} のrichDescription:`, findResult.richDescription);
        result.push({
          firstCategoryJa: firstCategory.name,
          firstCategoryEn: firstCategory.category,
          secondCategoryJa: findResult.name,
          secondCategoryEn: item,
          description: findResult.description || "", 
          adImage1: findResult.adImage1 || "", 
          adImage2: findResult.adImage2 || "", 
          bgImage: findResult.bgImage || "", 
          richDescription: findResult.richDescription || "",
        });
        return; // 見つかったらこのfirstCategoryの処理を終了
      }
      
      // 次にメインカテゴリを確認
      if (firstCategory.category === item) {
        console.log(`メインカテゴリ ${item} のrichDescription:`, firstCategory.richDescription);
        result.push({
          firstCategoryJa: firstCategory.name,
          firstCategoryEn: firstCategory.category,
          secondCategoryJa: "",
          secondCategoryEn: "",
          description: firstCategory.description || "", 
          adImage1: firstCategory.adImage1 || "", 
          adImage2: firstCategory.adImage2 || "", 
          bgImage: firstCategory.bgImage || "", 
          richDescription: firstCategory.richDescription || "",
        });
      }
    });
  });

  // 最終結果をデバッグ出力
  if (result.length > 0) {
    console.log(`getCategoryInfo 結果:`, result[0]);
  } else {
    console.log(`getCategoryInfo: 該当カテゴリが見つかりませんでした`);
  }

  return result;
};

export const getIndustoryInfo = (SecondCate: string[]) => {
  if (!SecondCate || !Array.isArray(SecondCate)) return [];

  const result = SecondCate.map((item) => {
    const findResult = industories.find(
      (industory) => industory.category === item
    );
    return findResult;
  }).filter(Boolean) as Industory[];

  return result;
};

// 各記事に対して動的に画像URLを生成する関数
export const createResizedImageUrl = (
  blobUrl: string,
  width: number,
  height: number
) => {
  const url = new URL(blobUrl);
  const pathSegments = url.pathname.split("/"); // URLのパス部分を '/' で分割
  const container = pathSegments[1]; // 'article'が入るようにインデックスを調整
  const path = pathSegments.slice(2).join("/"); // 'pathSegments[2]'以降のパスを抽出

  return `https://cache-blob-images.azurewebsites.net/images?container=${container}&path=${path}&width=${width}&height=${height}`;
};

export const formatDate = (date?: Date) => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
};
